@use "../../assets/styles/utils" as utils;
@use "../../../../node_modules/breakpoint-slicer" as bs;

.collapsed-section {
  width: 100%;
  font-size: utils.getRemFromPx(14);
  color: var(--header-text-color);
  padding: utils.getRemFromPx(16);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12);

  @include bs.from(l) {
    padding: utils.getRemFromPx(16) utils.getRemFromPx(25);
  }

  &--demarcated {
    border-left: 5px solid var(--warning-color);
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .heading-title {
      margin-right: utils.getRemFromPx(10);
      font-size: utils.getRemFromPx(16);
      font-weight: bold;
    }

    .heading-icon {
      width: utils.getRemFromPx(24);
      height: utils.getRemFromPx(24);

      path {
        stroke: var(--primary-text-color);
      }
    }
  }

  &__body {
    overflow: hidden;
    transition: height 0.4s ease-in-out;

    & > div:first-child {
      padding-top: utils.getRemFromPx(25);
    }
  }

  p {
    margin: 0;
  }
}
