@use "../../assets/styles/utils" as utils;
@use "../../../../node_modules/breakpoint-slicer/" as bs;

.modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--app-height, 100vh);
  background: rgba(0, 0, 0, 0.5);
  color: var(--primary-text-color);

  &__container-outer {
    position: relative;
    background-color: #fff;
    border-radius: 0.375rem;
    margin: auto;
    width: calc(100% - 3rem);

    .modal__container-inner {
      padding: utils.getRemFromPx(64) 1.5rem utils.getRemFromPx(44);
      max-width: 100%; /* breakpoints needed in the future */

      .modal__close-button {
        position: absolute;
        border-radius: 12px;
        padding: utils.getRemFromPx(9);
        background: var(--secondary-color);
        text-align: center;
        top: 0.75rem;
        right: 0.75rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;

        svg {
          width: utils.getRemFromPx(18);
          height: utils.getRemFromPx(18);
        }
      }

      .modal__subtitle {
        margin-top: 0.375rem;
        color: var(--inactive-color-accented);
      }
    }
  }

  &--compact {
    top: 50%;
    transform: translateY(-50%);
    overflow-y: auto;
    max-width: utils.getRemFromPx(366);
    max-height: calc(100% - var(--gutter-size) * 2);

    @include bs.to(s) {
      width: calc(100% - utils.getRemFromPx(24) * 2);
    }
  }

  &--fullscreen {
    top: 1.5rem;
    height: calc(100% - 3rem);
  }
}
