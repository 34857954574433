@use "../../../assets/styles/utils" as utils;

button {
  border: none;
  background-color: transparent;
}

.button {
  border-radius: 6px;
  text-align: center;
  font-size: utils.getRemFromPx(16);
  line-height: utils.getRemFromPx(20);
  cursor: pointer;
  padding: utils.getRemFromPx(15) utils.getRemFromPx(12);
  transition: all 200ms ease-in;
  color: var(--primary-text-color);
}

.button--primary {
  background-color: var(--primary-brand-color);
  color: #ffffff;
}

.button--secondary {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
}

.button--accent {
  background-color: var(--accent-color);
  color: #ffffff;
}

.button--inactive {
  background-color: var(--inactive-color);
  color: var(--primary-text-color);
  cursor: not-allowed;
}
