@use "../../assets/styles/utils" as utils;
@use "../../../../node_modules/breakpoint-slicer" as bs;

.card {
  padding: utils.getRemFromPx(27) utils.getRemFromPx(12);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: utils.getRemFromPx(6);
  overflow: auto;

  &__icon {
    font-size: utils.getRemFromPx(21);
    text-align: center;
  }

  &__content {
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 1rem;
    font-size: utils.getRemFromPx(14);
    line-height: utils.getRemFromPx(20);
    text-align: center;
    word-break: break-word;
    white-space: pre-line;

    @include bs.from(l) {
      font-size: utils.getRemFromPx(16);
      line-height: utils.getRemFromPx(24);
    }
  }
}

.card--success {
  background-color: var(--success-background-color);

  .card {
    &__icon {
      margin: utils.getRemFromPx(36) 0 utils.getRemFromPx(20) 0;
      width: utils.getRemFromPx(72);

      path {
        stroke: var(--success-color);
      }

      @include bs.from(l) {
        width: utils.getRemFromPx(85);
        margin: utils.getRemFromPx(51) 0 utils.getRemFromPx(53) 0;
      }
    }
  }

  @include bs.from(l) {
    height: 100%;
    max-height: utils.getRemFromPx(454);
  }
}

.card--error,
.card--warning {
  .card {
    &__icon {
      margin: utils.getRemFromPx(36) 0 utils.getRemFromPx(12);
      flex: 1 0 auto;

      svg {
        min-width: utils.getRemFromPx(78);
        min-height: utils.getRemFromPx(78);
      }

      @include bs.from(l) {
        min-width: utils.getRemFromPx(108);
        margin: utils.getRemFromPx(43) 0 utils.getRemFromPx(16) 0;
      }
    }
  }
}

.card--error {
  background-color: var(--error-background-color);
}

.card--warning {
  background-color: var(--warning-background-color);
}

.card--default {
  background-color: rgba(223, 223, 223, 0.25);
}
