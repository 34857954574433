@use "../../../../../node_modules/breakpoint-slicer" as bs;
@use "../../../assets/styles/utils" as utils;

.personal-data__fields {
  margin: 3.125rem 0 1.5rem;

  .datepicker {
    margin-bottom: utils.getRemFromPx(38.4);

    .input,
    .dropdown--info {
      margin-bottom: 0;
    }
  }

  .confirm-checkbox {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: utils.getRemFromPx(14);
    margin-top: utils.getRemFromPx(57) 0 utils.getRemFromPx(37);
    width: fit-content;

    &--error {
      color: var(--error-color);
    }
  }

  input[type="checkbox"] {
    display: block;
    width: utils.getRemFromPx(24);
    height: utils.getRemFromPx(24);
    background-color: var(--body-bg-color);
    margin-right: utils.getRemFromPx(10);

    &:checked {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      -webkit-appearance: none;
      outline: 0;
      background-image: url("../../../assets/images/checkbox_checked.svg");
    }

    &:not(:checked) {
      opacity: 0.8;
    }
  }
}
