@use "../../../assets/styles/utils" as utils;

.page-header {
  height: utils.getRemFromPx(48);
  background-color: var(--header-bg-color);
  color: var(--header-text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-shrink: 0;

  &__title h1 {
    font-size: utils.getRemFromPx(18);
    font-weight: 600;
    margin: 0;
  }
}
