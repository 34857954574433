@use '../../../assets/styles/utils' as utils;

.notification {
  z-index: 3;
  border-radius: utils.getRemFromPx(6);
  background-color: var(--success-color);
  color: #ffffff;
  padding: utils.getRemFromPx(16);
  position: relative;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: var(--gutter-size);
  }

  &:not(:first-child) {
    margin-top: var(--gutter-size);
  }

  cursor: pointer;

  &.notification--success {
    background-color: var(--success-color);
  }
  &.notification--warning {
    background-color: var(--warning-color);
  }
  &.notification--error {
    background-color: var(--error-color);
  }

  &__close-button {
    position: absolute;
    top: calc(#{utils.getRemFromPx(16)} - #{utils.getRemFromPx(8)});
    right: calc(#{utils.getRemFromPx(16)} - #{utils.getRemFromPx(8)});
    height: utils.getRemFromPx(8);
    width: utils.getRemFromPx(8);

    path {
      stroke: #ffffff;
    }
  }
}
