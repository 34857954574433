@use "../../../assets/styles/utils" as utils;
@use "../../../../../node_modules/breakpoint-slicer" as bs;

.error-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-bottom: utils.getRemFromPx(50);
  z-index: 2;

  &-card {
    max-height: 80%;
    width: calc(min(#{utils.getRemFromPx(500)}, 100%) - var(--gutter-size) * 2);

    &__button {
      width: 70%;
      margin-top: utils.getRemFromPx(30);
    }

    .title {
      margin-bottom: utils.getRemFromPx(13);
      font-weight: 500;
    }
  }

  .card__content {
    padding-bottom: utils.getRemFromPx(13);

    @include bs.from(l) {
      padding-bottom: utils.getRemFromPx(20);
    }
  }

  &__support.legend-text {
    padding-top: utils.getRemFromPx(30);
    font-size: utils.getRemFromPx(16);
    line-height: utils.getRemFromPx(19);
    width: auto;
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  }

  &__action-button {
    position: absolute;
    padding: 12px;
    width: 100%;
    bottom: 0;
    max-width: min(#{utils.getRemFromPx(500)}, 100%);

    .button {
      width: 100%;
    }
  }
}
