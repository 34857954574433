@use "../../assets/styles/utils" as utils;
@use "../../../../node_modules/breakpoint-slicer" as bs;

.page {
  display: flex;
  flex-flow: column;
  padding-top: utils.getRemFromPx(10);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;

  &--wide {
    .page-container {
      @include bs.from(l) {
        width: 1040px;
      }
    }
  }

  &-container {
    margin: 0 auto;

    @include bs.from(l) {
      width: 786px;
    }
  }

  .title-wrapper {
    padding-top: utils.getRemFromPx(38);
  }

  &.keyboard-padding {
    height: calc(100vh + 400px);
    padding-bottom: 400px;
  }

  &__icon {
    text-align: center;
  }

  &-content {
    flex: 1;
    padding-top: utils.getRemFromPx(13);
    width: 100%;

    &--small-margin &__container {
      @include bs.from(l) {
        max-width: utils.getRemFromPx(1074);
      }
    }
  }

  &__bottom {
    padding: var(--gutter-size);

    @include bs.from(l) {
      margin: 0 utils.getRemFromPx(40);
      padding: 0 0 var(--gutter-size) 0;
    }

    &--shadowed {
      box-shadow: 4px 2px 12px rgba(0, 0, 0, 0.12);
    }

    &--sticky {
      bottom: 0;
      position: sticky;
      background-color: var(--body-bg-color);
      margin: 0;

      @include bs.from(l) {
        padding: utils.getRemFromPx(15) utils.getRemFromPx(43) utils.getRemFromPx(22);
      }
    }
  }

  &.keyboard-padding {
    .page__bottom--sticky {
      bottom: -400px;
    }
  }
}
