@use "../../assets/styles/utils" as utils;
@use "../../../../node_modules/breakpoint-slicer/" as bs;

.qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: utils.getRemFromPx(40);

  &__icon {
    path {
      stroke: var(--primary-text-color) !important;
    }
    width: utils.getRemFromPx(160);
    height: utils.getRemFromPx(160);
    margin: utils.getRemFromPx(14) 0;
  }

  &__button {
    margin: utils.getRemFromPx(16) 0;
    width: utils.getRemFromPx(189);
  }

  &__camera {
    width: utils.getRemFromPx(350);
    height: utils.getRemFromPx(350);
    margin-top: utils.getRemFromPx(2);
  }

  &__camera-box {
    box-shadow: 0 0 0 100vmax rgb(255, 255, 255);
    z-index: -1;
  }

  &__hint {
    margin: utils.getRemFromPx(12);
    font-size: utils.getRemFromPx(14);
    text-align: center;
  }

  &__message.banner {
    margin-top: utils.getRemFromPx(129);
    min-height: utils.getRemFromPx(64);
    width: 100%;

    @include bs.from(l) {
      margin-top: utils.getRemFromPx(25);
    }
  }

  &__image {
    margin: utils.getRemFromPx(2);
    background-color: black;
    width: utils.getRemFromPx(400);
    height: utils.getRemFromPx(400);
    display: flex;
    justify-content: center;
    align-items: center;

    &__inner-square {
      background-color: white;
      width: utils.getRemFromPx(358);
      height: utils.getRemFromPx(358);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__switch-button {
    margin-bottom: utils.getRemFromPx(20);
    cursor: pointer;
  }
}
