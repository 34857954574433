@use "../../../assets/styles/utils" as utils;

.subtitle {
  color: var(--primary-text-color);
  font-size: utils.getRemFromPx(14);
  text-align: left;
  line-height: utils.getRemFromPx(18);
  white-space: break-spaces;

  &__position--center {
    text-align: center;
  }
}
