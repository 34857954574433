@use "../../assets/styles/utils" as utils;
@use "../../../../node_modules/breakpoint-slicer" as bs;
@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";

.daterange {
  .error-focusable {
    border-color: var(--error-color);
    .daterange__input-icon {
      path {
        fill: var(--error-color);
      }
    }
  }

  &__fields-wrapper {
    width: 100%;
    margin-bottom: utils.getRemFromPx(40);
  }

  &__input-container {
    padding: utils.getRemFromPx(16) utils.getRemFromPx(24);
    margin-bottom: utils.getRemFromPx(24);
  }

  &__input {
    width: 100%;
    height: utils.getRemFromPx(56);
    background: var(--secondary-color);
    border: 1px solid var(--inactive-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: utils.getRemFromPx(16) utils.getRemFromPx(18);
    cursor: pointer;

    &-icon {
      path {
        fill: var(--primary-brand-color);
      }
    }

    &-date {
      &--selected {
        font-weight: 700;
      }
    }
  }

  &__modal {
    .modal--compact {
      width: 100%;
      max-width: 100%;
      position: fixed;
      bottom: 0;
      top: initial;
      transform: none;
      border-radius: 0;
      max-height: 100%;

      @include bs.from(l) {
        top: 50%;
        position: relative;
        transform: translateY(-50%);
        border-radius: utils.getRemFromPx(4);
        max-width: utils.getRemFromPx(900);
      }

      .modal__container-inner {
        text-align: center;
        padding: 0;

        .modal__close-button {
          z-index: 5;
        }
      }
    }
    &--large {
      .modal__close-button {
        top: utils.getRemFromPx(96) !important;
      }
    }
  }

  &__calendar {
    display: none;
    padding: 0;

    @include bs.from(l) {
      padding: utils.getRemFromPx(36) utils.getRemFromPx(60) utils.getRemFromPx(24);
    }

    &--show {
      display: block;
      position: relative;
      margin-bottom: utils.getRemFromPx(8);
    }
  }

  &__footer {
    box-shadow: 4px 2px 12px rgba(0, 0, 0, 0.12);
    padding: utils.getRemFromPx(16) utils.getRemFromPx(24);

    &-text {
      font-size: utils.getRemFromPx(16);
      margin-bottom: utils.getRemFromPx(20);
      height: utils.getRemFromPx(19);
    }

    .button {
      width: 100%;
    }
  }

  //modify styles for calendar
  .rdrInfiniteMonths {
    height: 100% !important;
  }
  .rdrMonth {
    width: utils.getRemFromPx(300);
    padding: utils.getRemFromPx(10) 0 0;
    height: 100% !important;

    @include bs.from(l) {
      width: utils.getRemFromPx(387);
      padding: 0 utils.getRemFromPx(10);
    }
  }
  .rdrCalendarWrapper {
    width: 100%;

    //nth-child(2) = .rdrMonths on desktop
    & > div:nth-child(2) {
      font-family: Inter;
      font-size: utils.getRemFromPx(14);
      color: var(--primary-text-color);
      display: flex;
      flex-direction: column;
      align-items: center;
      height: utils.getRemFromPx(450);

      @include bs.from(l) {
        flex-direction: row;
        height: initial;
      }

      .rdrMonthName {
        font-size: utils.getRemFromPx(16);
        font-weight: bold;
        color: var(--primary-text-color);
        text-align: right;
        padding: utils.getRemFromPx(5) utils.getRemFromPx(14) utils.getRemFromPx(8);

        @include bs.from(l) {
          text-align: center;
        }
      }

      .rdrWeekDays {
        padding: utils.getRemFromPx(37) utils.getRemFromPx(5) utils.getRemFromPx(5);
        width: 100%;
        justify-content: center;
        box-shadow: 4px 25px 12px -22px rgb(0 0 0 / 12%);

        @include bs.from(l) {
          width: auto;
          padding: utils.getRemFromPx(10) 0 0;
          box-shadow: none;
        }

        .rdrWeekDay {
          color: var(--primary-text-color);
          font-weight: bold;
          max-width: 42px;

          @include bs.from(l) {
            max-width: initial;
          }
        }
      }

      .rdrDays {
        font-weight: normal;

        .rdrDayEndOfWeek,
        .rdrDayStartOfWeek {
          .rdrInRange {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      .rdrDay {
        .rdrDayInPreview,
        .rdrDayStartPreview,
        .rdrDayEndPreview {
          border: none;
        }

        &.rdrDayHovered {
          color: var(--body-bg-color);

          .rdrDayStartPreview,
          .rdrDayInPreview,
          .rdrDayEndPreview {
            border: none;
          }
        }
        //selected day
        .rdrStartEdge,
        .rdrEndEdge {
          & ~ .rdrDayNumber {
            font-weight: 700;
          }
        }
      }

      //style of number inside selected day
      .rdrDayToday .rdrDayNumber span {
        &:after {
          content: none;
        }
      }

      .rdrDayDisabled {
        background: transparent;

        .rdrDayNumber {
          span {
            color: var(--secondary-text-color);
          }
        }
      }
    }
  }

  //nav arrows
  .rdrMonthAndYearWrapper {
    display: none;

    @include bs.from(l) {
      display: flex;
    }

    span {
      font-size: utils.getRemFromPx(16);
    }

    @include bs.from(l) {
      position: absolute;
      top: 40%;
      width: 100%;
      left: 0;
    }

    .rdrNextPrevButton {
      background: transparent;

      i {
        border-width: utils.getRemFromPx(1) 0 0 utils.getRemFromPx(1);
        border-color: var(--primary-text-color);
        transform: rotate(314deg);
        height: utils.getRemFromPx(10);
        width: utils.getRemFromPx(10);
      }
    }

    .rdrNextButton {
      i {
        transform: rotate(135deg);
      }
    }
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrMonthAndYearPickers {
    @include bs.from(l) {
      display: none;
    }
  }
}
