@use "../../../../node_modules/breakpoint-slicer" as bs;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import "./sass-variables";

html,
body {
  margin: 0;
  font-size: $defaultFontSize;
  font-weight: 400;
  color: var(--primary-text-color);
  font-family: "Inter", sans-serif !important;
  transition: height 0.3s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100vh; //fallback value
  height: var(--app-height, 100vh);
}

body {
  &.keyboard-padding {
    height: calc(100vh - 400px);
  }

  .icon {
    vertical-align: middle;
  }

  .scrollbar {
    overflow: auto;
  }

  &:not(.mobile-env) * {
    ::-webkit-scrollbar {
      width: 6px !important;
      height: 6px !important;
    }

    ::-webkit-scrollbar-track {
      border-radius: 3px !important;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 3px !important;
      border: solid var(--primary-text-color) !important;
      background: var(--primary-text-color) !important;
    }

    @include bs.from(l) {
      ::-webkit-scrollbar {
        width: 12px !important;
        height: 12px !important;
      }

      ::-webkit-scrollbar-track {
        border-radius: 28px !important;
        background: var(--body-bg-color);
        box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.12);
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 28px !important;
      }
    }

    .scrollbar--color-brand {
      &::-webkit-scrollbar {
        width: 9px !important;
        height: 9px !important;

        @include bs.from(l) {
          width: 12px !important;
          height: 12px !important;
        }
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 28px !important;
        border-color: var(--primary-brand-color-dark) !important;
        background: var(--primary-brand-color-dark) !important;
        border: none !important;
        border-right: 1px solid white !important;
        border-bottom: 1px solid white !important;
      }
    }
  }

  /* hide number input spin button */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

//class for svg paths that need to have the brand color
.brand-color {
  stroke: var(--primary-brand-color);

  &--fill {
    fill: var(--primary-brand-color);
  }

  &-light {
    stroke: var(--primary-brand-color-light);

    &--fill {
      fill: var(--primary-brand-color-light);
    }
  }
}
