@use "../node_modules/breakpoint-slicer" as bs;
@use '../../../assets/styles/utils' as utils;

// todo: update styles to place the dropdown relative to button.
.lang-select {
  font-size: 1.125rem;
  cursor: pointer;
  color: var(--header-text-color);
  padding: utils.getRemFromPx(25);
  position: relative;
  line-height: utils.getRemFromPx(22);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  @include bs.from(l) {
    padding: utils.getRemFromPx(40);
  }

  &__dropdown {
    z-index: 1;
    background: var(--secondary-color);
    color: var(--header-text-color);
    position: absolute;
    top: 0;
    right: 0;
  }

  &__option {
    padding: utils.getRemFromPx(20) utils.getRemFromPx(25);

    @include bs.from(l) {
      padding: utils.getRemFromPx(40) utils.getRemFromPx(40);
    }
  }

  &__option--checked {
    font-weight: bold;
  }
}
