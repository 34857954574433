@use "../../../assets/styles/utils" as utils;
@use "../../../../../node_modules/breakpoint-slicer" as bs;

.legend-text {
  font-size: utils.getRemFromPx(11);
  line-height: utils.getRemFromPx(18);
  text-align: left;

  @include bs.from(l) {
    font-size: utils.getRemFromPx(12);
  }
}

.legend-text--button {
  text-align: center;
  color: var(--primary-brand-color);
  cursor: pointer;
}
