@use "../../../../../node_modules/breakpoint-slicer" as bs;
@use '../../../assets/styles/utils' as utils;

.button-group {
  display: flex;
  justify-content: space-between;

  &--stacked {
    flex-flow: column;

    .button {
      margin-bottom: var(--gutter-size);
    }
  }

  .button {
    width: 100%;

    &:not(:last-of-type) {
      margin-right: var(--gutter-size);
    }

    &:only-child {
      max-width: utils.getRemFromPx(500);
      margin: auto;
    }
  }
}
