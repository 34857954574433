@use "../../assets/styles/utils" as utils;

.banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-text-color);
  color: var(--body-bg-color);
  padding: utils.getRemFromPx(20) utils.getRemFromPx(28);
  min-height: utils.getRemFromPx(64);
  height: min-content;

  &__icon {
    margin-right: utils.getRemFromPx(14);

    svg {
      width: utils.getRemFromPx(24);
      height: utils.getRemFromPx(24);
    }
  }

  &__content {
    font-style: normal;
    font-weight: bold;
    font-size: utils.getRemFromPx(16);
    line-height: utils.getRemFromPx(19);
  }

  &--warning {
    background-color: var(--warning-background-color);
    color: inherit;
  }

  &--success {
    background-color: var(--success-background-color);
    padding: utils.getRemFromPx(5) utils.getRemFromPx(28);
    height: min-content;
    min-height: utils.getRemFromPx(42);
    color: var(--primary-text-color);

    .banner {
      &__icon {
        svg {
          width: utils.getRemFromPx(23);
          height: utils.getRemFromPx(23);

          path {
            stroke: var(--primary-brand-color);
          }
        }
      }

      &__title {
        color: var(--primary-text-color);
      }
    }
  }
}
