@use "../../assets/styles/utils" as utils;
@use "../node_modules/breakpoint-slicer" as bs;

.tile {
  border-radius: 12px;
  position: relative;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: block;
  font-weight: 700;

  &:after {
    border-radius: 12px;
    display: block;
    content: " ";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.0885417) 72.92%,
      #000000 100%
    );
  }

  &--radial-gradient {
    &:after {
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(0, 0, 0, 0.07) 0%,
        rgba(0, 0, 0, 0.27) 100%
      );
    }

    .tile__title {
      padding-bottom: utils.getRemFromPx(20);

      @include bs.from(l) {
        padding-bottom: utils.getRemFromPx(16);
      }
    }
  }

  &__title {
    color: #ffffff;
    font-size: utils.getRemFromPx(18);
    line-height: utils.getRemFromPx(27);
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 var(--gutter-size) utils.getRemFromPx(10);
    display: flex;
    flex-direction: column;
    gap: utils.getRemFromPx(12);

    @include bs.from(l) {
      font-size: utils.getRemFromPx(28);
      line-height: utils.getRemFromPx(34);
      gap: utils.getRemFromPx(10);
    }
  }

  &__arrow {
    position: absolute;
    right: 0;
    width: utils.getRemFromPx(40);
    height: 100%;
    background: rgba(255, 255, 255, 0.35);
    border-radius: 0 utils.getRemFromPx(12) utils.getRemFromPx(12) 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg path {
      stroke: var(--body-bg-color);
    }
  }
}
