@use "../../assets/styles/utils" as utils;
@use "../../../../node_modules/breakpoint-slicer" as bs;

.confirm-go-home-modal {
  &__button {
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: utils.getRemFromPx(12);
    }
  }

  &__content {
    text-align: center;
    font-size: utils.getRemFromPx(14);
    color: var(--inactive-color-accented);
    line-height: utils.getRemFromPx(20);
    padding: utils.getRemFromPx(25) utils.getRemFromPx(22) utils.getRemFromPx(38);
  }

  .modal__container-outer {
    max-width: min(calc(100% - utils.getRemFromPx(24) * 2), utils.getRemFromPx(366));
  }

  .modal__container-inner {
    padding: utils.getRemFromPx(56) utils.getRemFromPx(24) utils.getRemFromPx(26);
  }

  .title {
    font-weight: 500;
  }
}
