@use "../../assets/styles/utils" as utils;
@use "../node_modules/breakpoint-slicer" as bs;

.start-page {
  padding: 0;
  border-top: none;

  .container {
    height: 100%;
    max-width: 100%;
    width: 100%;
    padding: 0 utils.getRemFromPx(12) utils.getRemFromPx(16);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto utils.getRemFromPx(180);
    gap: utils.getRemFromPx(36) utils.getRemFromPx(12);
    margin: 0;

    @include bs.from(l) {
      gap: utils.getRemFromPx(40) utils.getRemFromPx(10);
      padding: 0 utils.getRemFromPx(13) utils.getRemFromPx(16);
    }
  }
}
