@use "../../assets/styles/utils" as utils;
@use "../../../../node_modules/breakpoint-slicer" as bs;

.loading-overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 6;
  background: rgba(255, 255, 255, 0.9);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  overflow: auto;
  white-space: pre-line;

  &--visible {
    display: flex;
  }

  &__description {
    flex-shrink: 0;
    font-size: utils.getRemFromPx(21);
    padding: utils.getRemFromPx(12) utils.getRemFromPx(12);
    text-align: center;
  }

  &__icon {
    flex-shrink: 0;

    width: utils.getRemFromPx(120);
    height: auto;

    @include bs.from(l) {
      width: utils.getRemFromPx(140);
      height: utils.getRemFromPx(123);
    }
  }
}
