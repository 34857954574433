@use "../../../assets/styles/utils" as utils;
@use "../node_modules/breakpoint-slicer" as bs;

.support-icon {
  cursor: pointer;
  padding: utils.getRemFromPx(10) utils.getRemFromPx(25);
  opacity: 0.6;

  svg {
    width: utils.getRemFromPx(28);
    height: utils.getRemFromPx(28);
    vertical-align: middle;
  }

  @include bs.from(l) {
    padding: utils.getRemFromPx(40);
  }
}
.support-modal {
  .collapsed-section {
    box-shadow: none;
    margin-top: utils.getRemFromPx(20);

    &__heading {
      justify-content: center;
    }
  }
}

.support-contact {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: utils.getRemFromPx(26);
  max-width: 30rem;

  &__icon {
    vertical-align: center;
    float: left;
    margin-right: 0.75rem;

    svg {
      height: utils.getRemFromPx(27);
      width: utils.getRemFromPx(27);

      path {
        fill: var(--primary-text-color);
      }
    }
  }

  &__text {
    font-size: 1.3125rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;

    svg {
      max-height: utils.getRemFromPx(24);
      fill: var(--primary-text-color);
      font-family: "Inter";
      max-width: 100%;
    }

    &--action {
      cursor: pointer;
    }
  }

  &__empty-text {
    text-align: center;
    margin-top: 2.5rem;
    font-size: 1.3125rem;
  }
}
