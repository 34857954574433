@use '../../assets/styles/utils' as utils;

.notifications-container {
  display: flex;
  justify-content: center;

  &--floating {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}

.notifications {
  width: calc(min(#{utils.getRemFromPx(1000)}, 100%) - var(--gutter-size) * 2);
  padding-top: utils.getRemFromPx(30);
  padding-bottom: utils.getRemFromPx(30);
  overflow: hidden;
}
