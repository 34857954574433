@use "../../assets/styles/utils" as utils;
@use "../../../../node_modules/breakpoint-slicer" as bs;

.input {
  position: relative;

  &__message {
    color: var(--error-color);
    font-size: utils.getRemFromPx(12);
    position: absolute;
    left: utils.getRemFromPx(5);
    bottom: utils.getRemFromPx(8);
  }
}

.input__content {
  line-height: utils.getRemFromPx(19);
  outline: none;
  border: none;
  border-bottom: 1px solid;
  border-color: var(--secondary-text-color);
  color: var(--primary-text-color);
  width: 100%;
  height: utils.$input-height;
  padding: utils.getRemFromPx(25) 0 utils.getRemFromPx(12);
  transition: all 200ms ease-in;
  font-size: utils.getRemFromPx(16);

  &.error--focusable {
    padding: utils.getRemFromPx(25) 0 0;
  }

  &:focus {
    border-width: utils.getRemFromPx(3.56);
    font-size: utils.getRemFromPx(19);
    outline: none;
    padding: utils.getRemFromPx(25) 0 utils.getRemFromPx(12);
  }

  &::placeholder {
    font-size: utils.getRemFromPx(12);
  }

  &:-ms-input-placeholder {
    font-size: utils.getRemFromPx(12);
  }

  &::-webkit-input-placeholder {
    font-size: utils.getRemFromPx(12);
  }
}

.input__description {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 0;
  transition: all 200ms ease-in-out;
  color: var(--primary-text-color);
  font-size: utils.getRemFromPx(14);
  top: 13px;
  white-space: nowrap;
}

.input:focus-within .input__description,
.input--filled .input__description {
  font-size: utils.getRemFromPx(12);
  top: 0;
  font-weight: 300;
}

.input__icon {
  position: absolute;
  right: utils.getRemFromPx(7);
  top: utils.getRemFromPx(22.8);
  width: utils.getRemFromPx(11.86);
  height: utils.getRemFromPx(10.4);

  @include bs.from(l) {
    top: utils.getRemFromPx(20);
  }
}

.input--success {
  .input__content {
    border-color: var(--primary-brand-color);
  }
  .input__icon path {
    fill: var(--success-color);
  }
}

.input--error {
  .input__content {
    border-color: var(--error-color);
  }

  .input__icon path {
    fill: var(--error-color);
  }

  .input__content {
    &::placeholder {
      color: var(--error-color);
      font-size: utils.getRemFromPx(12);
    }

    &:-ms-input-placeholder {
      color: var(--error-color);
      font-size: utils.getRemFromPx(12);
    }

    &::-webkit-input-placeholder {
      color: var(--error-color);
      font-size: utils.getRemFromPx(12);
    }
  }
}

.input--none {
  .input__content {
    border-color: var(--primary-text-color);

    &:focus {
      border-color: var(--primary-brand-color);
    }
  }
}

.input--error,
.input--success {
  .input__content {
    @include bs.from(l) {
      padding-bottom: 0;
    }
  }

  .input__description {
    padding-right: 1.125rem;
  }

  &:focus-within .input__description,
  &.input--filled .input__description {
    padding-right: 0;
  }
}

.input--box {
  .input__description {
    font-size: utils.getRemFromPx(12);
    transition: none;
    top: 0;
  }
  .input__content {
    margin-top: utils.getRemFromPx(18);
    height: utils.getRemFromPx(42);
    border: 1px solid var(--secondary-text-color);
    border-radius: 4px;
    padding: 0 utils.getRemFromPx(20) 0 utils.getRemFromPx(12);
  }
  .input__icon {
    top: utils.getRemFromPx(35);
  }
  &.input--error {
    .input__icon {
      display: none;
    }
    .input__content {
      border-color: var(--error-color);
    }
  }
  input:focus {
    font-size: utils.getRemFromPx(16);
  }
}
