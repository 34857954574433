@use "../../assets/styles/utils" as utils;

.datepicker {
  &__label {
    font-size: utils.getRemFromPx(14);
  }

  &__fields-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px var(--gutter-size);
    grid-template-areas: ". . .";
    margin-top: utils.getRemFromPx(10);
  }

  &__invalid-feedback {
    color: var(--error-color);
  }

  &__description {
    margin-top: utils.getRemFromPx(6);
    color: var(--error-color);
    font-size: utils.getRemFromPx(12);
  }
}
