@use '../../assets/styles/utils' as utils;

.inactivity-overlay {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: var(--inactive-color-accented);
  }

  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: utils.getRemFromPx(44);
    height: utils.getRemFromPx(44);
    margin: utils.getRemFromPx(16) 0 utils.getRemFromPx(24) 0;

    path {
      stroke: var(--primary-text-color);
    }
  }
}
