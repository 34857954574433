@use "../../../../node_modules/breakpoint-slicer" as bs;
@use "../../assets/styles/utils" as utils;

.page.search-page {
  .title-wrapper {
    padding-top: utils.getRemFromPx(15);
  }

  .container {
    padding: 0 var(--gutter-size) var(--gutter-size);
    position: relative;

    @include bs.from(l) {
      padding: utils.getRemFromPx(8) 0 0;
    }

    .input,
    .dropdown--info {
      margin: utils.getRemFromPx(10) 0 utils.getRemFromPx(38);

      &:last-child {
        margin: utils.getRemFromPx(10) 0;
      }

      @include bs.from(l) {
        margin: utils.getRemFromPx(10) 0 utils.getRemFromPx(41);
      }
    }

    .datepicker {
      margin-bottom: utils.getRemFromPx(38.4);

      .input,
      .dropdown--info {
        margin-bottom: 0;
      }
    }
  }

  .confirm-checkbox {
    margin-top: utils.getRemFromPx(40);

    @include bs.from(l) {
      margin-top: 0;
    }
  }

  .privacy-support {
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: utils.getRemFromPx(43);

    &__text {
      align-items: flex-start;

      @include bs.from(l) {
        align-items: center;
      }
    }

    @include bs.from(l) {
      justify-content: center;
    }
  }

  .booking__info {
    display: flex;
    line-height: 1.35;
    margin: utils.getRemFromPx(34) 0 utils.getRemFromPx(16);
    justify-content: flex-start;
    font-size: utils.getRemFromPx(11);

    .icon {
      margin-right: utils.getRemFromPx(15);
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
    }

    @include bs.from(l) {
      justify-content: center;
      margin-bottom: utils.getRemFromPx(27);
      font-size: utils.getRemFromPx(12);
    }
  }
}

.navigation {
  position: relative;

  &__scrollbar-container {
    padding: 0;
    position: relative;
    margin-bottom: utils.getRemFromPx(17);
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    display: flex;
    border-bottom: utils.getRemFromPx(0.8) solid var(--secondary-text-color);
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
    }
  }

  &__right-shadow,
  &__left-shadow {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    width: utils.getRemFromPx(20);
  }

  &__right-shadow {
    bottom: utils.getRemFromPx(14);
    right: -2px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  &__left-shadow {
    bottom: utils.getRemFromPx(7);
    left: -2px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &-tabs {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    &__item {
      display: inline-block;
      cursor: pointer;
      width: 100%;
      text-align: center;
      padding: utils.getRemFromPx(11);

      &--active {
        font-weight: 600;
        border-bottom: 4px solid var(--primary-text-color);
      }
    }

    &--3 {
      display: block;

      .navigation-tabs__item {
        width: auto;
        min-width: 33.3%;
      }
    }
  }
}
