@use "../../assets/styles/utils" as utils;
@use "../../../../node_modules/breakpoint-slicer" as bs;

.privacy-support {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: utils.getRemFromPx(13);

  @include bs.from(l) {
    margin-bottom: utils.getRemFromPx(17);
  }

  &--payment {
    margin-bottom: 0;

    @include bs.from(l) {
      margin-bottom: 0;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: utils.getRemFromPx(11);
    justify-content: center;

    @include bs.from(l) {
      font-size: utils.getRemFromPx(12);
    }
  }

  &__button {
    cursor: pointer;
  }

  .icon {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    margin-right: utils.getRemFromPx(15);
  }

  &__clickable-text {
    text-decoration: underline;
    text-decoration-color: var(--primary-text-color);
  }

  .qr-code {
    width: utils.getRemFromPx(96) !important;
    height: utils.getRemFromPx(96) !important;
    margin: utils.getRemFromPx(36);
  }

  &__modal {
    .modal--compact {
      max-width: 80vw;
      height: 80vh;

      .modal__container-inner {
        height: 100%;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
