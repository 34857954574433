@use "../../../../node_modules/breakpoint-slicer" as bs;
@use "../../assets/styles/utils" as utils;

.stepper {
  display: flex;
  justify-content: center;
  overflow: auto;
  flex-shrink: 0;

  &-container {
    margin: 0 auto;
    padding-left: 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    white-space: nowrap;

    &__item {
      width: utils.getRemFromPx(42);
      height: utils.getRemFromPx(42);
      text-align: center;
      border-radius: 50%;
      background: var(--primary-brand-color);
      margin: 0 utils.getRemFromPx(18);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      border: 2px solid var(--primary-brand-color);

      svg {
        path {
          color: #fff;
        }

        &.fill {
          path {
            fill: #fff;
          }
        }

        &.stroke {
          stroke: #fff;
          stroke-width: 1.25;
          g,
          path {
            stroke: #fff;
          }
        }
      }

      label {
        position: absolute;
        top: -100%;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        display: none;
      }

      &:first-child::before {
        display: none;
      }

      &--active {
        background: var(--primary-brand-color);
      }

      &--active ~ li {
        background: #fff;
        border: 2px solid var(--secondary-text-color);

        svg {
          path {
            color: var(--secondary-text-color);
          }

          &.fill {
            path {
              fill: var(--secondary-text-color);
            }
          }

          &.stroke {
            g,
            path {
              stroke: var(--secondary-text-color);
            }
          }
        }
      }
    }
  }

  //style for stepper with 5 steps
  .stepper-container {
    &.stepper-container--compressed {
      .stepper-container__item {
        margin: 0 utils.getRemFromPx(9);

        &::before {
          width: utils.getRemFromPx(10);
          left: -16px;
        }

        label {
          &::before {
            top: utils.getRemFromPx(18);
            left: -2rem;
            width: 2rem;
            height: utils.getRemFromPx(2.5);

            @include bs.from(m) {
              width: 4rem;
              left: -4rem;
              top: 1.3rem;
              height: 0.188rem;
            }

            @include bs.from(l) {
              left: -7rem;
              width: 7rem;
            }
          }
        }
      }
    }
  }

  //stepper connecting lines styles
  .stepper-container {
    &__item {
      &::before {
        content: "";
        position: absolute;
        width: utils.getRemFromPx(21);
        left: utils.getRemFromPx(-30);
        top: utils.getRemFromPx(18);
        height: 2px;
        background: var(--primary-brand-color);
        z-index: -1;

        @include bs.from(l) {
          left: -7rem;
          width: 7rem;
        }
      }
    }

    &.stepper-container--compressed {
      .stepper-container__item {
        margin: 0 utils.getRemFromPx(9);

        &::before {
          width: utils.getRemFromPx(10);
          left: -16px;
        }
      }
    }
  }

  .stepper-container,
  .stepper-container.stepper-container--compressed {
    .stepper-container__item {
      @include bs.from(m) {
        margin: 0 utils.getRemFromPx(18);

        &::before {
          width: utils.getRemFromPx(21);
          left: utils.getRemFromPx(-30);
        }
      }

      @include bs.from(l) {
        margin: 0 utils.getRemFromPx(31);

        &::before {
          width: utils.getRemFromPx(42);
          left: utils.getRemFromPx(-54);
        }
      }

      //check-in finish checkmark custom styles
      &.checkmark-holder {
        width: 0;
        height: 0;
        margin: 0;
        border: none !important;

        &::before {
          display: none;
        }

        .checkmark {
          display: none;
        }
      }

      &--active {
        & ~ li::before {
          background: var(--secondary-text-color);
        }

        &.checkmark-holder {
          .checkmark {
            display: block;
            position: absolute;
            bottom: utils.getRemFromPx(4);
            left: utils.getRemFromPx(-9);
            width: utils.getRemFromPx(20.6);
            height: utils.getRemFromPx(17.54);
            fill: var(--primary-brand-color);
          }
        }
      }
    }
  }
}
