@use "./utils" as utils;
@use "../../../../node_modules/breakpoint-slicer" as bs;

:root {
  // Primary Buttons, Stepper, Keys, clickable text…
  --primary-brand-color: #66af4c;
  --primary-brand-color-dark: #52a634;
  --primary-brand-color-light: #f3faef;

  // Text boxes, continuous text, titles,  fields
  --primary-text-color: #313639;

  // Inactive Buttons, underlined fields….
  --inactive-color: #d5d5d5;
  --inactive-color-accented: #798288;

  // Secondary Buttons, Stepper, Keys, Dividers…
  --secondary-color: #f5f5f5;

  // Out of Focus text → e.g. minimized field labels
  --secondary-text-color: #d5d5d5;

  // Accent Buttons, Stepper…
  --accent-color: #5a7c35;

  // Success icons, notifications
  --success-color: #74c542;
  --success-background-color: #e6f0db;

  // Warning icons, notifications
  --warning-color: #ffc107;
  --warning-background-color: #ffefc1;

  // Error icons, notifications
  --error-color: #f44336;
  --error-background-color: #fbd0cd;

  // Header Background
  --header-bg-color: #ffffff;

  // Header Title
  --header-text-color: #313639;

  // Header Icons / Options
  --header-accent-color: #d5d5d5;

  // Header Border
  --header-border-color: #d5d5d5;

  // Page Background
  --body-bg-color: #ffffff;

  --gutter-size: #{utils.getRemFromPx(12)};
  @include bs.from(l) {
    --gutter-size: #{utils.getRemFromPx(24)};
  }
  --wide-gutter-size: #{utils.getRemFromPx(29)};

  --border-color: #eaeaea;
}
