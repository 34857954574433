@use "../../../assets/styles/utils" as utils;
@use "../node_modules/breakpoint-slicer" as bs;

.title-wrapper {
  padding: 0 utils.getRemFromPx(12);
  margin-bottom: utils.getRemFromPx(25);

  @include bs.from(l) {
    padding: 0 utils.getRemFromPx(120);

    .title {
      font-size: utils.getRemFromPx(28);
      line-height: utils.getRemFromPx(34);
    }

    .subtitle {
      font-size: utils.getRemFromPx(16);
      line-height: utils.getRemFromPx(28);
    }
  }
}

.title {
  color: var(--primary-text-color);
  font-size: utils.getRemFromPx(21);
  font-weight: 700;
  text-align: left;
  margin-bottom: utils.getRemFromPx(7);

  &__size--small {
    font-size: utils.getRemFromPx(18);
  }

  &__size--large {
    font-size: utils.getRemFromPx(24);
  }

  &__size--extra-large {
    font-size: utils.getRemFromPx(36);
  }

  &__position--center {
    text-align: center;
  }
}
