@use "../../../assets/styles/utils" as utils;
@use "../../../../../node_modules/breakpoint-slicer" as bs;

.booking__intro {
  font-size: utils.getRemFromPx(14);
  line-height: 1.35;

  @include bs.from(l) {
    margin: 0 0 utils.getRemFromPx(27) 0;
  }
}

.booking__fields {
  margin-top: utils.getRemFromPx(30);
}
